const state = {
  surcharges: [],
};

const getters = {
  surcharges: (state) => state.surcharges,
};

const actions = {
  getSurcharges({ commit }, payload) {
    let { session, api, enabled } = payload;
    api.setHeaderToken(session.get("healex-token"));

    return new Promise((resolve, reject) => {
      api.GetSurchargesTypes(enabled).then(
        (response) => {
          commit("setSurcharges", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Surcharge Response: ", error);
          commit("setSurcharges", []);
          reject(error);
        }
      );
    });
  },
  AddSurcharge({ commit }, payload) {
    const { api, session, sName, idOU } = payload;

    api.setHeaderToken(session.get("healex-token"));

    return new Promise((resolve, reject) => {
      api.AddSurcharge(sName, idOU).then(
        (response) => {
          commit("addSurcharge", {
            idSurcharge: response.data.idEntity,
            surchargeName: sName,
            enabled: true,
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  DeleteSurcharge({ commit }, payload) {
    const { api, session, idSurcharge, idOU } = payload;
    api.setHeaderToken(session.get("healex-token"));

    return new Promise((resolve, reject) => {
      api.DeleteSurcharge(idSurcharge, idOU).then(
        (response) => {
          commit("deleteSurcharge", idSurcharge);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  UpdateSurcharge({ commit }, payload) {
    const { api, session, surcharge, idOU } = payload;
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.UpdateSurcharge(surcharge, idOU).then(
        (response) => {
          commit("updateSurcharge");
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  ActivateSurcharge({ commit }, payload) {
    const { api, session, idSurcharge, idOU } = payload;
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.ActivateSurcharge(idSurcharge, idOU).then(
        (response) => {
          commit("deleteSurcharge", idSurcharge);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};
const mutations = {
  setSurcharges: (state, surcharges) => {
    state.surcharges = surcharges;
  },
  addSurcharge: (state, surcharge) => {
    state.surcharges.push(surcharge);
  },
  deleteSurcharge: (state, idSurcharge) => {
    state.surcharges = state.surcharges.filter(function (obj) {
      return obj.idSurcharge !== idSurcharge;
    });
  },
  updateSurcharge: () => {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
