import Vue from "vue";
import VueI18n from "vue-i18n";
import VueCookies from "vue-cookies";

Vue.use(VueI18n);
Vue.use(VueCookies);
const supportedLocales = {
  en: "English",
  de: "Deutsh",
};
const getBrowserLocale = function (options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;
  if (!navigatorLocale) {
    return undefined;
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
};
const supportedLocalesInclude = function (locale) {
  return Object.keys(supportedLocales).includes(locale);
};
const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    number: {
      style: "decimal",
    },
  },
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    number: {
      style: "decimal",
    },
  },
};

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
function getStartingLocale() {
  const locale = Vue.$cookies.get("locale");

  if (locale) {
    return locale;
  } else {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true });
    if (supportedLocalesInclude(browserLocale)) {
      return browserLocale;
    } else {
      return process.env.VUE_APP_I18N_LOCALE || "en";
    }
  }
}
export default new VueI18n({
  numberFormats,
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
