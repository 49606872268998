const state = {
  versions: {},
};

const getters = {
  Versions: (state) => state.versions,
};

const actions = {
  getVersion({ commit }, payload) {
    let { session, api } = payload;
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.axios.get("/versions").then(
        (response) => {
          commit("setVersion", response.data);
          resolve(response);
        },
        (error) => {
          commit("setVersion", {});
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  setVersion(state, Version) {
    state.versions = Version;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
