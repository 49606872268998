const state = {
  Salaries: [],
  SalaryTypes: [],
  CategoriesSalaries: [],
  Seniority: [],
  Message: "",
  loading: false,
  SalaryTypeSelected: null,
  WorkingHours: null,
  salaryTypeData: {
    name: null,
    workingHours: 1,
  },
};
const getters = {
  Message: (state) => state.Message,
  Salaries: (state) => state.Salaries,
  CategoriesSalaries: (state) => state.CategoriesSalaries,
  SalaryTypes: (state) => state.SalaryTypes,
  Seniority: (state) => state.Seniority,
  loading: (state) => state.loading,
  SalaryTypeSelected: (state) => state.SalaryTypeSelected,
  SalaryTypeData: (state) => state.salaryTypeData,
  WorkingHours: (state) => state.WorkingHours,
};
const actions = {
  async putWorkingHours({ commit, state }, { session, api, yearlyWorkHours }) {
    try {
      api.setHeaderToken(session.get("healex-token"));
      const { idSalaryType, idCustomer } = state.WorkingHours;
      commit("request");
      await api.axios.put(
        `/customer/salary-type/${idSalaryType}`,
        yearlyWorkHours
      );
      commit("setWorkingHours", { idCustomer, idSalaryType, yearlyWorkHours });
    } catch (error) {
      console.log(error);
    } finally {
      commit("requested");
    }
  },
  async setSalaryTypeSelected(
    { commit },
    { session, api, SalaryTypeSelected }
  ) {
    api.setHeaderToken(session.get("healex-token"));
    commit("setSalaryTypeSelected", SalaryTypeSelected);
    commit("request");
    await api.axios
      .get(`/customer/salary-type/${SalaryTypeSelected}`)
      .then((response) => {
        commit("setWorkingHours", response.data);
      })
      .catch(() => {
        commit("setWorkingHours", null);
      });
  },
  removeSalaryType({ commit }) {
    commit("setSalaryTypeSelected", null);
    commit("request");
  },
  async fetchSalaryTypes({ commit }, payload) {
    let { session, api } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .getSalaryTypes()
      .then((response) => {
        // handle success
        commit("setSalaryTypes", response.data);
      })
      .catch((error) => {
        // handle error
        commit("setSalaryTypes", []);
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async fetchSalaries({ commit }, payload) {
    let { session, api, idSalaryType } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .getSalaries(idSalaryType)
      .then((response) => {
        // handle success
        commit("setSalaries", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        commit("setSalaries", []);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async getSeniority({ commit }, payload) {
    let { session, api, idSalaryType } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .getSeniority(idSalaryType)
      .then((response) => {
        // handle success
        commit("setSeniority", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        commit("setSeniority", []);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  fetchCategoriesSalaries({ commit }, payload) {
    let { api, idSalaryType } = payload;
    commit("request");
    return new Promise((resolve, reject) => {
      api
        .getSalaryCategories(idSalaryType)
        .then((response) => {
          // handle success
          commit("setCategoriesSalaries", response.data);
          resolve(response);
        })
        .catch((error) => {
          // handle error
          commit("setCategoriesSalaries", []);
          console.log(error);
          reject(error);
        })
        .then(function () {
          // always executed
          commit("requested");
        });
    });
  },
  async updateSalaries({ commit }, payload) {
    let { session, api, idSalaryType, salaries } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .updateSalaries({ idSalaryType, salaries })
      .then((response) => {
        // handle success
        commit("setMessage", response.data.message);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  createCategory({ commit }, payload) {
    const { api, idSalaryType } = payload;
    return new Promise((resolve, reject) => {
      api.createCategory(idSalaryType).then(
        (response) => {
          commit("appendCategory", response.data.category);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  updateCategory({ commit }, payload) {
    const { api, idSalaryType, idCategory, name } = payload;
    return new Promise((resolve, reject) => {
      api.updateCategory(idSalaryType, idCategory, name).then(
        (response) => {
          commit("updateCategory", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  deleteCategory({ commit }, payload) {
    const { api, idSalaryType, idCategory } = payload;
    return new Promise((resolve, reject) => {
      api.deleteCategory(idSalaryType, idCategory).then(
        () => {
          commit("deleteCategory", idCategory);
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  createSeniority({ commit }, payload) {
    const { api, idSalaryType } = payload;
    return new Promise((resolve, reject) => {
      api.createSeniority(idSalaryType).then(
        (response) => {
          console.log(response.data);
          commit("appendSeniority", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  deleteSeniority({ commit }, payload) {
    const { api, idSalaryType, idSeniority } = payload;
    return new Promise((resolve, reject) => {
      api.deleteSeniority(idSalaryType, idSeniority).then(
        () => {
          commit("deleteSeniority", idSeniority);
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  updateSeniority({ commit }, payload) {
    const { api, idSalaryType, idSeniority, description } = payload;
    return new Promise((resolve, reject) => {
      api.updateSeniority(idSalaryType, idSeniority, description).then(
        (response) => {
          commit("updateSeniority", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  createSalaryType({ commit }, payload) {
    const { api, name, workingHours } = payload;
    return new Promise((resolve, reject) => {
      api.postSalaryType(name, workingHours).then(
        (response) => {
          commit("appendSalaryType", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  updateSalaryType({ commit }, payload) {
    const { api, idSalaryType, name, workingHours } = payload;
    return new Promise((resolve, reject) => {
      api.putSalaryType(idSalaryType, name, workingHours).then(
        (response) => {
          commit("updateSalaryType", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  getSalaryType({ commit }, payload) {
    const { api, idSalaryType } = payload;
    return new Promise((resolve, reject) => {
      api.getSalaryType(idSalaryType).then(
        (response) => {
          commit("setSalaryTypeData", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  deleteSalaryType({ commit }, payload) {
    const { api, idSalaryType } = payload;
    return new Promise((resolve, reject) => {
      api.deleteSalaryType(idSalaryType).then(
        (response) => {
          commit("deleteSalaryType", idSalaryType);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  updateSalaryValue({ commit }, payload) {
    const { api, idSalaryType, idCategory, idSeniority, salary } = payload;
    return new Promise((resolve, reject) => {
      api.postSalaryValue(idSalaryType, idCategory, idSeniority, salary).then(
        (response) => {
          commit("updateSalary", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  uploadCSV({ commit }, payload) {
    const { api, idSalaryType, jsonFile } = payload;
    return new Promise((resolve, reject) => {
      api.axios
        .put(`/salaries/types/${idSalaryType}/csv`, {
          JSON: jsonFile,
        })
        .then(
          (response) => {
            commit("setMessage", "");
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },
  downloadCSV({ commit }, payload) {
    const { api, idSalaryType } = payload;
    return new Promise((resolve, reject) => {
      api
        .axios({
          url: `/salaries/types/${idSalaryType}/csv`,
          method: "GET",
          responseType: "blob",
        })
        .then(
          (response) => {
            commit("setMessage", "");
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },
};
const mutations = {
  setWorkingHours: (state, WorkingHours) => {
    state.WorkingHours = WorkingHours;
  },
  setSalaryTypeSelected: (state, SalaryTypeSelected) => {
    state.SalaryTypeSelected = SalaryTypeSelected;
  },
  setMessage: (state, message) => {
    state.Message = message;
  },
  setSalaries: (state, salaries) => {
    state.Salaries = salaries;
  },
  updateSalary: (state, salary) => {
    const index = state.Salaries.indexOf(
      (x) =>
        x.idCategory == salary.idCategory && x.idSeniority == salary.idSeniority
    );
    state.Salaries[index] = salary;
  },
  setCategoriesSalaries: (state, CategoriesSalaries) => {
    state.CategoriesSalaries = CategoriesSalaries;
  },
  setSeniority: (state, Seniority) => {
    state.Seniority = Seniority;
  },
  setSalaryTypes: (state, salaryTypes) => {
    state.SalaryTypes = salaryTypes;
  },
  request: (state) => {
    state.loading = true;
  },
  requested: (state) => {
    state.loading = false;
  },
  appendCategory: (state, newCategory) => {
    state.CategoriesSalaries.push(newCategory);
  },
  updateCategory: (state, categoryInfo) => {
    const index = state.CategoriesSalaries.findIndex(
      (x) => categoryInfo.idCategory == x.idCategory
    );
    state.CategoriesSalaries[index].categoryName = categoryInfo.categoryName;
  },
  deleteCategory: (state, idCategory) => {
    const index = state.CategoriesSalaries.findIndex(
      (x) => x.idCategory == idCategory
    );
    state.CategoriesSalaries.splice(index, 1);
  },
  appendSeniority: (state, newSeniority) => {
    state.Seniority.splice(
      state.Seniority.length - 2,
      0,
      newSeniority.seniority
    );
  },
  deleteSeniority: (state, idSeniority) => {
    const index = state.Seniority.findIndex(
      (x) => x.idSeniority == idSeniority
    );
    state.Seniority.splice(index, 1);
  },
  updateSeniority: (state, seniorityInfo) => {
    const index = state.Seniority.findIndex(
      (x) => seniorityInfo.idSeniority == x.idSeniority
    );
    state.Seniority[index].description = seniorityInfo.description;
  },
  appendSalaryType: (state, newSalaryType) => {
    state.SalaryTypes.push(newSalaryType);
  },
  setSalaryTypeData: (state, salaryTypeData) => {
    state.SalaryTypeData = salaryTypeData;
  },
  updateSalaryType: (state, salaryType) => {
    const index = state.SalaryTypes.indexOf(
      (x) => x.idSalaryType == salaryType.idSalaryType
    );
    state.SalaryTypes.splice(index, 1);
    state.SalaryTypes.push(salaryType);
    state.WorkingHours.yearlyWorkHours = salaryType.yearlyWorkingHours;
  },
  deleteSalaryType: (state, idSalaryType) => {
    const index = state.SalaryTypes.indexOf(
      (x) => x.idSalaryType == idSalaryType
    );
    state.SalaryTypes.splice(index, 1);
    state.WorkingHours = null;
    state.SalaryTypeSelected = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
