<template>
  <div class="login-view bg-healex-gradient">
    <b-container>
      <div class="box text-center">
        <b-card class="inner p-2 p-md-5 shadow">
          <img class="helex-log" src="@/assets/logo.png" />
          <simple-loader v-if="loading" />
          <b-form v-if="!loading" @submit.prevent="onSubmit" @reset="onReset">
            <b-card-text class="text-left text-uppercase mb-5">
              <locale-changer isNav />
              <h3>{{ $t("login.login") }}</h3>
            </b-card-text>
            <b-input-group class="mb-3">
              <b-input-group-prepend is-text>
                <b-icon icon="person-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                v-model="form.UserName"
                :placeholder="$t('login.form.username')"
                type="text"
                required
                id="LoginUserName"
              />
            </b-input-group>
            <b-input-group class="mb-5">
              <b-input-group-prepend is-text>
                <b-icon icon="lock-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                v-model="form.Password"
                :placeholder="$t('login.form.password')"
                type="password"
                required
                id="LoginPassword"
              />
            </b-input-group>
            <b-row class="mb-3">
              <b-col sm="12" md="6" class="mb-2 mb-md-0">
                <b-button
                  id="LoginSignIn"
                  type="submit"
                  block
                  variant="primary"
                  >{{ $t("login.button.signin") }}</b-button
                >
              </b-col>
              <b-col sm="12" md="6">
                <b-button
                  @click="$router.push('/register')"
                  block
                  variant="primary"
                  id="LoginRegister"
                  >{{ $t("login.button.register") }}</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-link @click="SMSLogin" id="LoginClinicalSite">{{
                  $t("login.login-with")
                }}</b-link>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-link
                  :href="registerClinicalSite"
                  id="RegisterClinicalSite"
                  >{{ $t("login.register-with") }}</b-link
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-link to="/password-recovery" id="LoginPasswordRecovery">{{
                  $t("login.recovery")
                }}</b-link>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-link
                  @click="$bvModal.show('data-privacy-modal')"
                  id="LoginDataPrivacy"
                  >{{ $t("dataPrivacy.title") }}</b-link
                >
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
      <b-alert
        :show="sessionExpired"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000"
        variant="warning"
        dismissible
      >
        {{ $t("login.expiredSession") }}
      </b-alert>
      <NewPassword :show="showChangePassword" />
      <b-modal
        id="modal-concurrency"
        :title="$t('login.anotherSessionOpened.title')"
        @ok="handleOk"
        @cancel="onLogout"
        @close="onLogout"
        >{{ $t("login.anotherSessionOpened.description") }}
        <template #modal-footer="{ ok, cancel }">
          <b-button
            variant="secondary"
            @click="cancel()"
            id="SessionWarningCancel"
          >
            {{ $t("general.cancel") }}
          </b-button>
          <b-button variant="primary" @click="ok()" id="SessionWarningOk">
            {{ $t("general.ok") }}
          </b-button>
        </template>
      </b-modal>
    </b-container>
    <DataPrivacyModal />
  </div>
</template>

<script>
import localeChanger from "@/components/general/locale-changer";
import NewPassword from "@/components/NewPassword";
import DataPrivacyModal from "@/components/general/data-privacy";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "login-view",
  components: {
    localeChanger,
    NewPassword,
    DataPrivacyModal,
  },
  data() {
    return {
      form: {
        UserName: "",
        Password: "",
      },
      showChangePassword: false,
      registerClinicalSite: process.env.VUE_APP_HEALEX_SELF_SERVICE_REGISTER,
      show: null,
    };
  },
  watch: {},
  methods: {
    ...mapActions({
      login: "LOGIN",
      smsLogin: "SMSLOGIN",
      finishAllSessions: "FINISHALLSESSIONS",
      getSubMenu: "GET_SUBMENU",
      onLogout: "LOGOUT",
      getFeatures: "GET_FEATURES",
    }),
    handleOk() {
      const that = this;
      this.finishAllSessions({ api: this.$api }).finally(() => {
        if (that.concurrency === false) {
          this.$store.dispatch("GET_SUBMENU", { api: this.$api });

          this.getFeatures({ api: this.$api });
          that.$router.push("/");
        }
      });
    },
    onSubmit() {
      const that = this;
      this.login({ api: that.$api, form: that.form }).then(
        (response) => {
          if (!response.data.passwordChanged) {
            that.$bvModal.show("new-password-modal");
            return;
          }
          if (that.concurrency === false) {
            that.$router.push("/");
            this.getFeatures({ api: this.$api });
          } else {
            that.$bvModal.show("modal-concurrency");
          }
        },
        (error) => {
          console.log("Error: ", error);
          that.$bvToast.toast(error, {
            variant: "danger",
            title: "Login Error",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      );
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    SMSLogin() {
      const that = this;
      this.$oidcClient.getSignedIn().then(
        (signIn) => {
          if (signIn)
            this.$oidcClient.getUser().then((userInfo) => {
              this.smsLogin({
                api: that.$api,
                accessToken: userInfo.access_token,
              }).then(
                () => {
                  if (that.concurrency === false) {
                    that.$router.push("/");
                    this.getFeatures({ api: this.$api });
                  } else {
                    that.$bvModal.show("modal-concurrency");
                  }
                },
                (error) => {
                  console.log("Error: ", error);
                  that.$bvToast.toast(error, {
                    variant: "danger",
                    title: "Login Error",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                }
              );
            });
          else {
            this.$oidcClient.signIn();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      loading: "GET_LOADING",
      sessionExpired: "SessionExpired",
    }),
    ...mapGetters({
      message: "GET_MESSAGE",
      concurrency: "GET_CONCURRENCY",
    }),
  },
};
</script>

<style scoped>
.login-view {
  position: relative;
  width: 100%;
  height: 100%;
  background: #dedede;
}

.login-view .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-view .box .inner {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.login-view .helex-log {
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}
</style>
