<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <router-view />
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish();
    this.setRoles(this.$store._vm.$session.get("healex-userRoles"));
  },
  created() {
    //Get Status
    this.getStatus({ api: this.$api });
    this.getFeatures({ api: this.$api });
    this.setRoles(this.$store._vm.$session.get("healex-userRoles"));
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish();
    });
  },
  methods: {
    ...mapActions({
      getStatus: "GET_STATUS",
      setRoles: "STORE_ROLES",
      getFeatures: "GET_FEATURES",
    }),
  },
};
</script>

<style>
body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: #f9f8f8;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
