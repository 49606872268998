const state = {
  generalSummations: [],
};

const getters = {
  generalSummations: (state) => state.generalSummations,
};

const actions = {
  getGeneralSummation({ commit }, payload) {
    let { session, api, enabled } = payload;
    api.setHeaderToken(session.get("healex-token"));

    return new Promise((resolve, reject) => {
      api.getGeneralSummation(enabled).then(
        (response) => {
          commit("setGeneralSummations", response.data);
          resolve(response);
        },
        (error) => {
          console.log("General summations: ", error);
          commit("setGeneralSummations", []);
          reject(error);
        }
      );
    });
  },

  addGeneralSummation({ commit }, payload) {
    const { api, session, generalSummationName } = payload;
    api.setHeaderToken(session.get("healex-token"));

    return new Promise((resolve, reject) => {
      api.postGeneralSummation(generalSummationName).then(
        (response) => {
          commit("addNewGeneralSummation", {
            idGeneralSummation: response.data.idGeneralSummation,
            generalSummationName: response.data.generalSummationName,
            enabled: response.data.enabled,
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  updateGeneralSummationName({ commit }, payload) {
    const { api, generalSummationName, idGeneralSummation } = payload;
    return new Promise((resolve, reject) => {
      api
        .putGeneralSummationName(idGeneralSummation, generalSummationName)
        .then(
          (response) => {
            commit("setGeneralSummationName");
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },

  updateGeneralSummationState({ commit }, payload) {
    const { api, idGeneralSummation, status } = payload;
    return new Promise((resolve, reject) => {
      api.putGeneralSummationStatus(idGeneralSummation, status).then(
        (response) => {
          commit("deleteGeneralSummation", idGeneralSummation);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};
const mutations = {
  setGeneralSummations: (state, generalSummations) => {
    state.generalSummations = generalSummations;
  },

  addNewGeneralSummation: (state, newGeneralSummation) => {
    state.generalSummations.push(newGeneralSummation);
  },

  setGeneralSummationName: () => {},

  deleteGeneralSummation: (state, idGeneralSummation) => {
    state.generalSummations = state.generalSummations.filter(function (obj) {
      return obj.idGeneralSummation !== idGeneralSummation;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
