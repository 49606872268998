const state = {
  parameters: [],
};

const getters = {
  parameters: (state) => state.parameters,
};

const actions = {
  getParameters({ commit }, payload) {
    let { api, idOrganizationalUnit, enabled } = payload;
    return new Promise((resolve, reject) => {
      api.axios
        .get(
          `/parameters/organizational-unit/${idOrganizationalUnit}?enabled=${enabled}`
        )
        .then(
          (response) => {
            commit("setParameters", response.data);
            resolve(response);
          },
          (error) => {
            console.log("Get parameters error: ", error);
            commit("setParameters", []);
            reject(error);
          }
        );
    });
  },
  postParameter({ commit }, { api, idOrganizationalUnit, parameterName }) {
    return new Promise((resolve, reject) => {
      api.axios
        .post(`/parameter`, {
          idOrganizationalUnit,
          parameterName,
          enabled: true,
        })
        .then(
          (response) => {
            commit("addParameter", response.data);
            resolve(response);
          },
          (error) => {
            console.log("Post parameter error: ", error);
            reject(error);
          }
        );
    });
  },
  putParameter({ commit }, { api, idParameter, parameterName }) {
    return new Promise((resolve, reject) => {
      api.axios
        .put(`/parameter/${idParameter}`, {
          name: parameterName,
        })
        .then(
          (response) => {
            commit("updateParameter", response.data);
            resolve(response);
          },
          (error) => {
            console.log("Post parameter error: ", error);
            reject(error);
          }
        );
    });
  },
  enableParameter({ commit }, { api, idParameter }) {
    return new Promise((resolve, reject) => {
      api.axios.put(`/parameter/${idParameter}/enabled`).then(
        (response) => {
          commit("deleteParameter", idParameter);
          resolve(response);
        },
        (error) => {
          console.log("Activate parameter error: ", error);
          reject(error);
        }
      );
    });
  },
  deleteParameter({ commit }, { api, idParameter }) {
    return new Promise((resolve, reject) => {
      api.axios.delete(`/parameter/${idParameter}`).then(
        () => {
          commit("deleteParameter", idParameter);
          resolve();
        },
        (error) => {
          console.log("Delete parameter error: ", error);
          reject(error);
        }
      );
    });
  },
};
const mutations = {
  setParameters: (state, parameters) => {
    state.parameters = parameters;
  },
  addParameter: (state, parameter) => {
    state.parameters.push(parameter);
  },
  updateParameter: (state, parameter) => {
    var oldParam = state.parameters.find(
      (param) => param.idParameter == parameter.idParameter
    );
    oldParam.parameterName = parameter.parameterName;
  },
  deleteParameter: (state, idParameter) => {
    state.parameters = state.parameters.filter(
      (param) => param.idParameter != idParameter
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
