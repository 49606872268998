import Vue from "vue";

const state = {
  customers: [],
  customerSettings: {
    months: null,
    surcharge: null,
  },
  customer: null,
  enabled: true,
  customersFeatures: [],
};

const getters = {
  customers: (state) => state.customers,
  customerSettings: (state) => state.customerSettings,
  customer: (state) => state.customer,
  customersFeatures: (state) => state.customersFeatures,
};

const actions = {
  getCustomers({ commit }, payload) {
    const { api, enabled } = payload;
    return new Promise((resolve, reject) => {
      api.axios.get(`/customers?enable=${enabled}`).then(
        (response) => {
          commit("setCustomers", response.data);
          resolve(response);
        },
        (error) => {
          commit("setCustomers", []);
          reject(error);
        }
      );
    });
  },
  AddCustomer({ commit }, payload) {
    const { api, sName } = payload;
    return new Promise((resolve, reject) => {
      api.AddCustomer(sName).then(
        (response) => {
          commit("addCustomer", {
            idCustomer: response.data.idEntity,
            nameCustomer: sName,
            enabled: true,
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  DeleteCustomer({ commit }, payload) {
    const { api, idCustomer } = payload;
    return new Promise((resolve, reject) => {
      api.DeleteCustomer(idCustomer).then(
        (response) => {
          commit("deleteCustomer", idCustomer);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  DeleteCustomerPermanently({ commit }, payload) {
    const { api, idCustomer } = payload;
    return new Promise((resolve, reject) => {
      api.DeleteCustomerPermanently(idCustomer).then(
        (response) => {
          commit("deleteCustomer", idCustomer);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  ActiveCustomer({ commit }, payload) {
    const { api, idCustomer } = payload;
    return new Promise((resolve, reject) => {
      api.ActiveCustomer(idCustomer).then(
        (response) => {
          commit("deleteCustomer", idCustomer);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  getCustomerSettings({ commit }, { api }) {
    return new Promise((resolve, reject) => {
      api.axios.get(`/customer/settings`).then(
        (response) => {
          commit("setCustomerSettings", response.data);
          resolve(response);
        },
        (error) => {
          commit("setCustomerSettings", {
            months: null,
            surcharge: null,
          });
          reject(error);
        }
      );
    });
  },
  putCustomerSettings({ commit }, { api, months, surcharge }) {
    return new Promise((resolve, reject) => {
      api.axios.put(`/customer/settings`, { months, surcharge }).then(
        (response) => {
          commit("setCustomerSettings", response.data);
          resolve(response);
        },
        (error) => {
          console.log("post customer settings: ", error);
          reject(error);
        }
      );
    });
  },
  getCustomer({ commit }, { api, idCustomer }) {
    return new Promise((resolve, reject) => {
      api.axios.get(`/customers/${idCustomer}`).then(
        (response) => {
          commit("setCustomer", response.data);
          resolve(response);
        },
        (error) => {
          console.log("get customer: ", error);
          reject(error);
        }
      );
    });
  },
  putCustomer({ commit }, payload) {
    const { api, nameCustomer, unlimited, maxUsers, idCustomer } = payload;
    return new Promise((resolve, reject) => {
      api.axios
        .put(`/customers/${idCustomer}`, {
          nameCustomer,
          unlimited,
          maxUsers,
        })
        .then(
          (response) => {
            commit("updateCustomerList", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },
  deleteCustomer({ commit }, payload) {
    const { api, idCustomer } = payload;
    return new Promise((resolve, reject) => {
      api.axios.put(`/customers/${idCustomer}/disabled`).then(
        (response) => {
          commit("deleteCustomer", idCustomer);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  activateCustomer({ commit }, payload) {
    const { api, idCustomer } = payload;
    return new Promise((resolve, reject) => {
      api.axios.put(`/customers/${idCustomer}/enabled`).then(
        (response) => {
          commit("deleteCustomer", idCustomer);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  deleteCustomerPermanently({ commit }, payload) {
    const { api, idCustomer } = payload;
    return new Promise((resolve, reject) => {
      api.axios.delete(`/customers/${idCustomer}`).then(
        (response) => {
          commit("deleteCustomer", idCustomer);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  postCustomer({ commit }, payload) {
    const { api, nameCustomer, unlimited, maxUsers } = payload;
    return new Promise((resolve, reject) => {
      api.axios
        .post(`/customers`, {
          name: nameCustomer,
          unlimited,
          maxUser: maxUsers,
        })
        .then(
          (response) => {
            commit("appendCustomer", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  },
  getCustomerFeatures({ commit }, payload) {
    const { idCustomer } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios.get(`/customers/${idCustomer}/features`).then(
        (response) => {
          commit("setCustomerFeatures", response.data);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  putCustomerFeature({ commit }, payload) {
    const { idCustomer, idFeature, enabled } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios
        .put(`/customers/${idCustomer}/features/${idFeature}`, { enabled })
        .then(
          (response) => {
            commit("updateCustomerFeature", response.data);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          }
        );
    });
  },
};

const mutations = {
  setCustomers: (state, customers) => {
    state.customers = customers;
  },
  addCustomer: (state, customer) => {
    state.customers.push(customer);
  },
  deleteCustomerPermanently: (state, idCustomer) => {
    state.customers = state.customers.filter(function (obj) {
      return obj.idCustomer !== idCustomer;
    });
  },
  setCustomerSettings: (state, customerSettings) => {
    state.customerSettings = customerSettings;
  },
  setCustomer: (state, customer) => {
    state.customer = customer;
  },
  updateCustomerList: (state, updatedCustomer) => {
    const value = state.customers.find(
      (customer) => customer.idCustomer == updatedCustomer.idCustomer
    );
    const index = state.customers.indexOf(value);
    state.customers.splice(index, 1, updatedCustomer);
  },
  deleteCustomer: (state, idCustomer) => {
    const value = state.customers.find(
      (customer) => customer.idCustomer == idCustomer
    );
    const index = state.customers.indexOf(value);
    state.customers.splice(index, 1);
  },
  appendCustomer: (state, newCustomer) => {
    state.customers.push(newCustomer);
  },
  setCustomerFeatures: (state, features) => {
    state.customersFeatures = features;
  },
  updateCustomerFeature: (state, feature) => {
    const index = state.customersFeatures.findIndex(
      (x) => x.idFeature === feature.idFeature
    );
    state.customersFeatures[index].enabled = feature.enabled;
    state.customersFeatures[index].idFeature = feature.idFeature;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
