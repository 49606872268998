import Vue from "vue";
import Vuex from "vuex";
import Cases from "./modules/cases";
import Case from "./modules/case";
import CaseTemplates from "./modules/CaseTemplates";
import Subcases from "./modules/Subcases";
import Salary from "./modules/Salary";
import OrganizationalUnit from "./modules/OrganizationalUnit";
import Customer from "./modules/Customer";
import User from "./modules/User";
import Surcharges from "./modules/Surcharges";
import Conditions from "./modules/Conditions";
import ResourcePool from "./modules/ResourcePool";
import MasterForm from "./modules/MasterForm";
import Parameters from "./modules/Parameters";
import GeneralSummations from "./modules/GeneralSummations";
import Versions from "./modules/Versions";
import MaterialCosts from "./modules/MaterialCosts";
import Help from "./modules/Help";
import router from "@/router";
import CostCalculationComments from "./modules/CostCalculationComments";

Vue.use(Vuex);

const initialStoreModules = {
  Cases,
  Case,
  Salary,
  OrganizationalUnit,
  Customer,
  User,
  Surcharges,
  Conditions,
  Subcases,
  ResourcePool,
  MasterForm,
  CaseTemplates,
  Parameters,
  GeneralSummations,
  Versions,
  MaterialCosts,
  Help,
  CostCalculationComments,
};

const defaultState = {
  sessionExpired: localStorage.getItem("sessionExpired"),
  loading: false,
  isLoggedIn: false,
  message: null,
  passwordChanged: null,
  userName: localStorage.getItem("authentication.userName"),
  name: localStorage.getItem("authentication.name"),
  displayOU: false,
  allowSettingScreen: localStorage.getItem("authentication.allowSettingScreen"),
  subMenus: JSON.parse(localStorage.getItem("authentication.subMenus") || "{}"),
  concurrency: null,
  userRoles: null,
  CaseStatus: [],
  SubcaseStatus: [],
  MasterFormStatus: [],
  features: [],
};

let store = new Vuex.Store({
  state: { ...defaultState },
  getters: {
    getCaseStatus: (state) => state.CaseStatus,
    getSubcaseStatus: (state) => state.SubcaseStatus,
    getMasterFormStatus: (state) => state.MasterFormStatus,
    SessionExpired: (state) => state.sessionExpired,
    GET_MESSAGE: (state) => {
      return state.message;
    },
    GET_LOADING: (state) => {
      return state.loading;
    },
    GET_PASSWORDCHANGED: (state) => {
      return state.passwordChanged;
    },
    GET_USERNAME: (state) => {
      return state.userName;
    },
    GET_NAME: (state) => {
      return state.name;
    },
    GET_DISPLAY_OU: (state) => {
      return state.displayOU;
    },
    GET_ALLOWSETTINGSCREEN: (state) => {
      return state.allowSettingScreen;
    },
    GET_SUBMENUS: (state) => {
      return state.subMenus;
    },
    GET_CONCURRENCY: (state) => {
      return state.concurrency;
    },
    GET_USERROLES: (state) => {
      return state.userRoles;
    },
    GET_ISADMIN: (state) => {
      return state.userRoles.isAdmin;
    },
    GET_ISADMINCUSTOMER: (state) => {
      return state.userRoles.isAdminCustomer;
    },
    GET_ISREVIEWER: (state) => {
      return state.userRoles.isReviewer;
    },
    GET_ISLOGGED: (state) => {
      return state.isLoggedIn;
    },
    GET_FEATURES: (state) => {
      return state.features;
    },
  },
  mutations: {
    setCaseStatus: (state, CaseStatus) => (state.CaseStatus = CaseStatus),
    setSubcaseStatus: (state, SubcaseStatus) =>
      (state.SubcaseStatus = SubcaseStatus),
    setMasterFormStatus: (state, MasterFormStatus) =>
      (state.MasterFormStatus = MasterFormStatus),
    SetSessionExpired: (state, sessionExpired) => {
      state.sessionExpired = sessionExpired;
      localStorage.setItem("sessionExpired", sessionExpired ? 1 : 0);
    },
    resetState(state) {
      state = { ...defaultState };
      for (var key in initialStoreModules) {
        state[key] = { ...initialStoreModules[key].state };
      }
    },
    REQUEST: (state) => {
      state.loading = true;
    },
    SUCCESS(
      state,
      {
        passwordChanged,
        token,
        tokenExpire,
        userName,
        name,
        allowSettingScreen,
        concurrency,
        userRoles,
      }
    ) {
      state.loading = false;
      state.isLoggedIn = true;
      state.userName = userName;
      state.name = name;
      state.passwordChanged = passwordChanged;
      state.allowSettingScreen = allowSettingScreen;
      state.concurrency = concurrency;
      state.userRoles = userRoles;
      localStorage.setItem("authentication.userName", userName);
      localStorage.setItem("authentication.name", name);
      localStorage.setItem(
        "authentication.allowSettingScreen",
        allowSettingScreen
      );
      store._vm.$session.start();
      store._vm.$session.set("healex-token", token);
      store._vm.$session.set("healex-userRoles", userRoles);
      store._vm.$session.set("healex-tokenExpire", tokenExpire);
      store._vm.$session.set("healex-userName", userName);
      store._vm.$session.set("healex-name", name);
      store._vm.$session.set("healex-passwordChanged", passwordChanged);
    },
    SUCCESS_REFRESH(
      state,
      { passwordChanged, token, tokenExpire, userName, name, concurrency }
    ) {
      state.loading = false;
      state.isLoggedIn = true;
      state.passwordChanged = passwordChanged;
      state.concurrency = concurrency;
      store._vm.$session.set("healex-token", token);
      store._vm.$session.set("healex-tokenExpire", tokenExpire);
      store._vm.$session.set("healex-userName", userName);
      store._vm.$session.set("healex-name", name);
    },
    ERROR(state) {
      state.loading = false;
      state.isLoggedIn = false;
      state.message = "Error";
    },
    DESTROY(state) {
      state.loading = false;
      state.isLoggedIn = false;
      state.message = null;
      state.userName = "";
      state.name = "";
      state.allowSettingScreen = false;
      state.displayOU = false;
      state.passwordChanged = null;

      localStorage.setItem("authentication.subMenus", []);
      localStorage.setItem("authentication.userName", "");
      localStorage.setItem("authentication.name", "");

      store._vm.$session.clear();
      store._vm.$session.destroy();
    },
    SET_DISPLAY_OU(state, displayOU) {
      state.displayOU = displayOU;
    },
    SET_SUBMENU(state, subMenus) {
      state.subMenus = subMenus;
      localStorage.setItem("authentication.subMenus", JSON.stringify(subMenus));
    },
    SET_CONCURRENCY(state, concurrency) {
      state.concurrency = concurrency;
    },
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    SET_ROLES(state, roles) {
      state.userRoles = roles;
    },
    SET_FEATURES(state, features) {
      state.features = features;
    },
  },
  actions: {
    GET_STATUS({ commit }, { api }) {
      api
        .getStatus()
        .then((response) => {
          var caseStatus = [];
          var subcaseStatus = [];
          var masterFormStatus = [];

          for (let i = 0; i < response.data.case.length; i++) {
            caseStatus.push({
              value: response.data.case[i].statusName,
              text: "status." + response.data.case[i].idStatus,
            });
          }

          for (let i = 0; i < response.data.subcase.length; i++) {
            subcaseStatus.push({
              value: response.data.subcase[i].statusName,
              text: "status." + response.data.subcase[i].idStatus,
            });
          }

          for (let i = 0; i < response.data.masterForm.length; i++) {
            masterFormStatus.push({
              value: response.data.masterForm[i].statusName,
              text: "status." + response.data.masterForm[i].idStatus,
            });
          }

          caseStatus.unshift({
            value: null,
            text: "general.status",
          });
          subcaseStatus.unshift({
            value: null,
            text: "general.status",
          });
          masterFormStatus.unshift({
            value: null,
            text: "general.status",
          });

          commit("setCaseStatus", caseStatus);
          commit("setSubcaseStatus", subcaseStatus);
          commit("setMasterFormStatus", masterFormStatus);
        })
        .catch(() => {
          commit("setCaseStatus", []);
          commit("setSubcaseStatus", []);
          commit("setMasterFormStatus", []);
        });
    },
    FINISHALLSESSIONS({ commit }, payload) {
      const { api } = payload;

      return new Promise((resolve, reject) => {
        api
          .finishSesssions()
          .then((response) => {
            commit("SET_CONCURRENCY", false);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    LOGIN({ commit, dispatch }, payload) {
      const { api, form } = payload;
      return new Promise((resolve, reject) => {
        api.login(form).then(
          (response) => {
            if (response.status === 200) {
              api.setHeaderToken(response.data.token);
              commit("SetSessionExpired", false);
              commit("SUCCESS", response.data);
              dispatch("GET_STATUS", { api });
              if (!response.data.concurrency) {
                dispatch("GET_SUBMENU", payload);
              }
              resolve(response);
            } else {
              commit("ERROR");
              reject(response.data.message);
            }
          },
          (error) => {
            commit("ERROR");
            reject(error.response?.data.message);
          }
        );
      });
    },
    LOGOUT({ commit }, payload) {
      store.commit("Cases/setCaseSelectd", {
        caseName: null,
        closedOn: null,
        createdBy: null,
        createdOn: null,
        idCase: 0,
        idOrganizationalUnit: 0,
        lastModified: null,
        organizationalUnitName: null,
        owner: null,
        status: null,
      });
      if (!payload) {
        commit("DESTROY");
        commit("resetState");
        router.push("/login");
      }
      const { api } = payload;
      return new Promise((resolve, reject) => {
        api
          .logout()
          .then((response) => {
            commit("DESTROY");
            commit("resetState");
            router.push("/login");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    ExpireSession({ commit }) {
      commit("SetSessionExpired", true);
    },
    DISPLAY_OU({ commit }, payload) {
      const { enable } = payload;
      commit("SET_DISPLAY_OU", enable);
    },
    SMSLOGIN({ commit, dispatch }, payload) {
      let { api, accessToken } = payload;
      return new Promise((resolve, reject) => {
        api.smsLogin(accessToken).then(
          (response) => {
            if (response.status === 200) {
              api.setHeaderToken(response.data.token);
              commit("SetSessionExpired", false);
              commit("SUCCESS", response.data);
              resolve(response);
              if (!response.data.concurrency) {
                dispatch("GET_SUBMENU", payload);
              }
            } else {
              commit("ERROR");
              reject(response.data.message);
            }
          },
          (error) => {
            commit("ERROR");
            router.push("/login");
            reject(error.response.data.message);
          }
        );
      });
    },
    SET_LANGUAGE() {
      const { api } = arguments[1];
      return new Promise((resolve, reject) => {
        api.SetLanguage(arguments[1]).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    STORE_ROLES({ commit }, payload) {
      commit("SET_ROLES", payload);
    },
    GET_SUBMENU({ commit }, payload) {
      const { api } = payload;
      return new Promise((resolve, reject) => {
        api.Settings(arguments[1]).then(
          (response) => {
            resolve(response);
            commit("SET_SUBMENU", response.data);
          },
          (error) => {
            reject(error);
            commit("SET_SUBMENU", []);
          }
        );
      });
    },
    SHOW_MESSAGE({ commit }, { idMessage, message, variant }) {
      commit("SET_MESSAGE", { idMessage, message, variant });
    },
    DELETE_MESSAGE({ commit }) {
      commit("SET_MESSAGE", null);
    },
    REFRESH_TOKEN({ commit }, { api }) {
      api.refreshToken().then((response) => {
        if (response.status === 200) {
          api.setHeaderToken(response.data.token);
          commit("SetSessionExpired", false);
          commit("SUCCESS_REFRESH", response.data);
        }
      });
    },
    GET_FEATURES({ commit }, { api }) {
      api.axios.get(`/user/features`).then((response) => {
        commit("SET_FEATURES", response.data);
      });
    },
  },
  modules: { ...initialStoreModules },
});
export default store;
