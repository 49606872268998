import vue from "vue";

const state = {
  conditions: [],
  surcharges: [],
};

const getters = {
  conditions: (state) => state.conditions,
  surchargeTypes: (state) => state.surcharges,
};

const actions = {
  getConditions({ commit }, payload) {
    let { enabled } = payload;
    return new Promise((resolve, reject) => {
      vue.axios.get(`/Customer/Surcharge/Conditions?enabled=${enabled}`).then(
        (response) => {
          commit("setConditions", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Condition Response: ", error);
          commit("setConditions", []);
          reject(error);
        }
      );
    });
  },
  AddCondition({ commit }, payload) {
    const { api, session, sName, idOU } = payload;

    api.setHeaderToken(session.get("healex-token"));

    return new Promise((resolve, reject) => {
      api.AddCondition(sName, idOU).then(
        (response) => {
          commit("addCondition", {
            idCondition: response.data.idEntity,
            sumlageName: sName,
            enabled: true,
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  DeleteCondition({ commit }, payload) {
    const { api, session, idCondition, idOU } = payload;
    api.setHeaderToken(session.get("healex-token"));

    return new Promise((resolve, reject) => {
      api.DeleteCondition(idCondition, idOU).then(
        (response) => {
          commit("deleteCondition", idCondition);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  UpdateCondition({ commit }, payload) {
    const { api, session, condition, idOU } = payload;
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.UpdateCondition(condition, idOU).then(
        (response) => {
          commit("updateCondition");
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  ActivateCondition({ commit }, payload) {
    const { api, session, idCondition, idOU } = payload;
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.ActivateCondition(idCondition, idOU).then(
        (response) => {
          commit("deleteCondition", idCondition);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  GetSurchargeTypes({ commit }, payload) {
    let { session, api, idCondition } = payload;
    api.setHeaderToken(session.get("healex-token"));

    return new Promise((resolve, reject) => {
      api.GetConditionValues(idCondition).then(
        (response) => {
          commit("setSurchargeTypes", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Condition Response: ", error);
          commit("setConditions", []);
          reject(error);
        }
      );
    });
  },
  UpdateSurcharges({ commit }, payload) {
    const { api, session, idCondition, values } = payload;
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.UpdateConditionValues(idCondition, values).then(
        (response) => {
          commit("updateCondition");
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
};
const mutations = {
  setConditions: (state, conditions) => {
    state.conditions = conditions;
  },
  addCondition: (state, condition) => {
    state.conditions.push(condition);
  },
  deleteCondition: (state, idCondition) => {
    state.conditions = state.conditions.filter(function (obj) {
      return obj.idCondition !== idCondition;
    });
  },
  updateCondition: () => {},
  setSurchargeTypes: (state, surcharges) => {
    state.surcharges = surcharges;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
