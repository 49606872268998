import vue from "vue";
const state = {
  helpArticles: [],
  helpArticle: null,
  ArticleName: null,
  ArticleDescription: null,
  helpFiles: [],
  helpFile: null,
  FilePath: null,
  message: "",
};

const getters = {
  helpArticles: (state) => state.helpArticles,
  helpArticle: (state) => state.helpArticle,
  helpFiles: (state) => state.helpFiles,
  helpFile: (state) => state.helpFile,
  message: (state) => state.message,
};

const actions = {
  getHelpArticles({ commit }) {
    return new Promise((resolve, reject) => {
      vue.axios.get("help-articles").then(
        (response) => {
          commit("setHelpArticles", response.data);
          resolve(response);
        },
        (error) => {
          commit("setHelpArticles", []);
          reject(error);
        }
      );
    });
  },
  getHelpArticle({ commit }, payload) {
    let { idArticle } = payload;
    return new Promise((resolve, reject) => {
      vue.axios.get(`help-articles/${idArticle}`).then(
        (response) => {
          commit("setHelpArticle", response.data);
          resolve(response);
        },
        (error) => {
          commit("setHelpArticle", null);
          reject(error);
        }
      );
    });
  },
  postArticle({ commit }, payload) {
    let { ArticleName, ArticleDescription } = payload;
    return new Promise((resolve, reject) => {
      vue.axios.post(`help-articles`, { ArticleName, ArticleDescription }).then(
        (response) => {
          commit("appendArticle", response.data.object);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  putArticle({ commit }, payload) {
    let { idArticle, ArticleName, ArticleDescription } = payload;
    return new Promise((resolve, reject) => {
      vue.axios
        .put(`help-articles/${idArticle}`, { ArticleName, ArticleDescription })
        .then(
          (response) => {
            commit("setArticle", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          }
        );
    });
  },
  deleteArticle({ commit }, payload) {
    let { idHelpArticle } = payload;
    return new Promise((resolve, reject) => {
      vue.axios.delete(`help-articles/${idHelpArticle}`).then(
        (response) => {
          commit("eraseArticle", idHelpArticle);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  getHelpFiles({ commit }, payload) {
    let { idArticle } = payload;
    return new Promise((resolve, reject) => {
      vue.axios.get(`help-article-file/${idArticle}/HelpArticle`).then(
        (response) => {
          commit("setHelpFiles", response.data);
          resolve(response);
        },
        (error) => {
          commit("setHelpFiles", []);
          reject(error);
        }
      );
    });
  },
  getHelpFile({ commit }, payload) {
    let { idFile } = payload;
    return new Promise((resolve, reject) => {
      vue.axios.get(`help-article-file/${idFile}`).then(
        (response) => {
          commit("setHelpFile", response.data);
          resolve(response);
        },
        (error) => {
          commit("setHelpFile", null);
          reject(error);
        }
      );
    });
  },
  postFile({ commit }, payload) {
    let { idArticle, data, fileName, contentType } = payload;
    const dataArray = Array.from(data);
    return new Promise((resolve, reject) => {
      vue.axios
        .post(`help-article-file`, {
          idArticle,
          data: dataArray,
          fileName,
          contentType,
        })
        .then(
          (response) => {
            commit("appendFile", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          }
        );
    });
  },
  putFile({ commit }, payload) {
    let { idFile, idArticle, data, fileName, contentType } = payload;
    const dataArray = Array.from(data);
    return new Promise((resolve, reject) => {
      vue.axios
        .put(`help-article-file/${idFile}`, {
          idArticle,
          data: dataArray,
          fileName,
          contentType,
        })
        .then(
          (response) => {
            commit("setFile", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          }
        );
    });
  },
  deleteFile({ commit }, payload) {
    let { idFile } = payload;
    return new Promise((resolve, reject) => {
      vue.axios.delete(`help-article-file/${idFile}`).then(
        (response) => {
          commit("eraseFile", idFile);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  getDownloadFileArticle({ commit }, payload) {
    let { idFile } = payload;
    return new Promise((resolve, reject) => {
      vue
        .axios({
          url: `help-article-file/${idFile}/FileContent`,
          method: "GET",
          responseType: "blob",
        })
        .then(
          (response) => {
            commit("setMessage", "");
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          }
        );
    });
  },
};

const mutations = {
  setHelpArticles(state, helpArticles) {
    state.helpArticles = helpArticles;
  },
  setHelpArticle(state, helpArticle) {
    state.helpArticle = helpArticle;
  },
  appendArticle(state, newArticle) {
    state.helpArticles.push(newArticle);
  },
  setArticle(state, helpArticle) {
    const index = state.helpArticles.findIndex(
      (x) => x.idArticle === helpArticle.idArticle
    );
    state.helpArticles[index].articleName = helpArticle.articleName;
    state.helpArticles[index].articleDescription =
      helpArticle.articleDescription;
  },
  eraseArticle: (state, idHelpArticle) => {
    const index = state.helpArticles.findIndex(
      (x) => x.idArticle == idHelpArticle
    );
    if (index != -1) {
      state.helpArticles.splice(index, 1);
    }
  },
  setHelpFiles(state, helpFiles) {
    state.helpFiles = helpFiles;
  },
  setHelpFile(state, helpFile) {
    state.helpFile = helpFile;
  },
  appendFile(state, newFile) {
    state.helpFiles.push(newFile);
  },
  setFile(state, helpFile) {
    const index = state.helpFiles.findIndex(
      (x) => x.idFile === helpFile.idFile
    );
    state.helpFiles[index].fileName = helpFile.fileName;
  },
  eraseFile: (state, idFile) => {
    const index = state.helpFiles.findIndex((x) => x.idFile == idFile);
    state.helpFiles.splice(index, 1);
  },
  setMessage: (state, message) => {
    state.message = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
