import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Error404 from "../views/Error404.vue";
import ConfirmationEmail from "../views/ConfirmationEmail.vue";
import store from "../store/index";
Vue.prototype;

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/emailconfirmation",
    name: "EmailConfirmation",
    component: ConfirmationEmail,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "CasesList",
        path: "",
        component: () => import("../views/Cases.vue"),
      },
      {
        name: "Cases",
        path: "case/:idCase",
        component: () => import("../views/Cases.vue"),
        meta: {
          eraseMessage: true,
        },
      },
      {
        name: "Case",
        path: "case/header/:idCase",
        component: () => import("../views/HeaderCase.vue"),
        meta: {
          eraseMessage: true,
        },
      },
      {
        name: "UserCollaboration",
        path: "usercollaboration/:idCase",
        component: () => import("../views/UserCollaboration.vue"),
      },
      {
        name: "Settings",
        path: "/settings/administrative-costs",
        component: () => import("../views/administrative-costs-new"),
        meta: {
          idScreen: 4,
          eraseMessage: true,
        },
      },
      {
        name: "SalaryType",
        path: "/settings/administrative-costs/:idSalaryType",
        component: () => import("../views/administrative-costs-new"),
        meta: {
          eraseMessage: true,
        },
      },
      {
        name: "Customer",
        path: "/settings/customers",
        component: () => import("../views/Customer.vue"),
        meta: {
          idScreen: 1,
          eraseMessage: true,
        },
      },
      {
        name: "Users",
        path: "/settings/user",
        component: () => import("../views/Users.vue"),
        meta: {
          idScreen: 5,
          settings: true,
          eraseMessage: true,
        },
      },
      {
        name: "User",
        path: "/settings/user/:user",
        component: () => import("../views/User.vue"),
        meta: {
          idScreen: 5,
          settings: true,
          eraseMessage: true,
        },
      },
      {
        name: "AddUser",
        path: "/settings/add-user/",
        component: () => import("../views/User.vue"),
        meta: {
          idScreen: 5,
          settings: true,
          eraseMessage: true,
        },
      },
      {
        name: "Roles",
        path: "/settings/user/:user/roles",
        component: () => import("../views/Roles.vue"),
        meta: {
          idScreen: 5,
          eraseMessage: true,
        },
      },
      {
        name: "OrgUnits",
        path: "/settings/organizational-unit",
        component: () => import("../views/OrgUnit.vue"),
        meta: {
          idScreen: 3,
          eraseMessage: true,
        },
      },
      {
        name: "Subcase",
        path: "/subcase/:idSubcase",
        component: () => import("../views/PartialCostCalculation.vue"),
        props: true,
      },
      {
        name: "ListMasterForm",
        path: "/settings/master-form",
        component: () => import("../views/ListMasterForm.vue"),
        props: true,
        meta: {
          idScreen: 2,
          eraseMessage: true,
        },
      },
      {
        name: "MasterForm",
        path: "/settings/master-form/:idMasterForm",
        component: () => import("../views/MasterForm.vue"),
        props: true,
        meta: {
          screenName: "Surcharges",
          idScreen: 2,
        },
      },
      {
        name: "Surcharges",
        path: "/settings/surcharges",
        component: () => import("../views/Surcharges.vue"),
        meta: {
          idScreen: 6,
          eraseMessage: true,
        },
      },
      {
        name: "Conditions",
        path: "/settings/conditions",
        component: () => import("../views/Conditions.vue"),
        meta: {
          idScreen: 7,
          eraseMessage: true,
        },
      },
      {
        path: "/settings/casetemplatelist",
        name: "caseListTemplate",
        component: () => import("../views/CaseTemplateList.vue"),
        meta: {
          idScreen: 8,
          eraseMessage: true,
        },
      },
      {
        name: "UserProfile",
        path: "/userprofile",
        component: () => import("../views/UserProfile.vue"),
      },
      {
        name: "CaseTemplateEdition",
        path: "/case-templates/:idCaseTemplate",
        component: () => import("../views/CaseTemplateEdition.vue"),
        meta: {
          idScreen: 8,
          eraseMessage: true,
        },
      },
      {
        name: "Parameters",
        path: "/settings/organizational-units/:idOrganizationalUnit/parameters",
        component: () => import("../views/Parameters.vue"),
        meta: {
          idScreen: 3,
          eraseMessage: true,
        },
      },
      {
        name: "GeneralSummations",
        path: "/settings/general-summations",
        component: () => import("../views/GeneralSummations.vue"),
        meta: {
          idScreen: 9,
          eraseMessage: true,
        },
      },
      {
        name: "about",
        path: "/about",
        component: () => import("../views/About.vue"),
      },
      {
        name: "Help",
        path: "/help",
        component: () => import("../views/Help.vue"),
      },
    ],
  },
  {
    path: "/login/callback",
    name: "LoginCallback",
    component: () => import("../views/LoginCallback.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: () => import("../views/PasswordRecovery.vue"),
  },
  {
    path: "*",
    name: "404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function checkPermissionScreen(idScreen, screens) {
  return screens.find((element) => element.screen == idScreen);
}

router.beforeEach((to, from, next) => {
  store.dispatch("DISPLAY_OU", { enable: false });
  if (from.matched.some((route) => route.meta && route.meta.eraseMessage)) {
    store.dispatch("DELETE_MESSAGE");
  }
  if (to.matched.some((route) => route.meta && route.meta.requiresAuth)) {
    if (!router.app.$session.exists()) {
      next({
        path: "/login",
      });
    } else {
      if (to.matched.some((route) => route.meta && route.meta.settings)) {
        store.dispatch("DISPLAY_OU", { enable: true });
      }

      if (
        to.matched.some(
          (route) =>
            route.meta &&
            route.meta.idScreen &&
            !checkPermissionScreen(
              route.meta.idScreen,
              store.getters["GET_SUBMENUS"]
            )
        )
      ) {
        return next("/unauthorized");
      }
      next();
    }
  } else {
    if (to.matched.some((route) => route.meta && route.meta.settings)) {
      store.dispatch("DISPLAY_OU", { enable: true });
    }
    if (
      to.matched.some(
        (route) =>
          route.meta &&
          route.meta.idScreen &&
          !checkPermissionScreen(
            route.meta.idScreen,
            store.getters["GET_SUBMENUS"]
          )
      )
    ) {
      return next("/unauthorized");
    }
    next();
  }
});

export default router;
