import Vue from "vue";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueProgressBar from "vue-progressbar";
import "./styles/custom.scss";
import i18n from "./i18n";
import { API } from "./services/api";
import VueSession from "vue-session";
import OidcClient from "./services/auth";
import VueCurrencyInput from "vue-currency-input";
import VueCookies from "vue-cookies";
import SimpleLoader from "@/components/general/simple-loader.vue";
import "handsontable/dist/handsontable.full.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
require("./plugins/fontawesome");
import BackButton from "@/components/BackButton.vue";
import CustomPercentInput from "@/components/general/CustomPercent/";

Vue.use(VueSession, { persist: true });
Vue.use(VueCurrencyInput);
Vue.prototype.$oidcClient = new OidcClient();

const api = new API();
const axiosPlugin = {
  install(Vue) {
    Vue.prototype.$api = api;
  },
};

Vue.use(VueAxios, api.axios);
Vue.use(VueProgressBar);
Vue.use(axiosPlugin);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.use(CustomPercentInput);

Vue.component("simple-loader", SimpleLoader);
Vue.component("vue-simple-context-menu", VueSimpleContextMenu);
Vue.component("back-button", BackButton);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
