import Vue from "vue";
import axios from "axios";
const defaultCases = {
  caseName: null,
  closedOn: null,
  createdBy: null,
  createdOn: null,
  idCase: 0,
  idOrganizationalUnit: 0,
  lastModified: null,
  organizationalUnitName: null,
  owner: null,
  status: null,
};

const state = {
  organizations: [],
  cases: [],
  loading: false,
  isAdmin: false,
  UserList: [],
  caseSelectd: defaultCases,
  caseHeader: {},
  masterFormList: [],
  userCollaboration: [],
  caseTemplates: [],
  caseTemplatesbyOU: [],
  reviewersList: [],
};

const getters = {
  casesList: (state) => state.cases,
  organizationalUnits: (state) => state.organizations,
  loading: (state) => state.loading,
  isAdmin: (state) => state.isAdmin,
  userList: (state) => state.UserList,
  caseSelectd: (state) => state.caseSelectd,
  caseHeader: (state) => state.caseHeader,
  masterFormList: (state) => state.masterFormList,
  userCollaboration: (state) => state.userCollaboration,
  caseTemplates: (state) => state.caseTemplates,
  caseTemplatesbyOU: (state) => state.caseTemplatesbyOU,
  parameters: (state) =>
    state.caseHeader.file?.parameters ? state.caseHeader.file.parameters : [],
  reviewersList: (state) => state.reviewersList,
};

const actions = {
  async fetchCases({ commit }) {
    commit("request");
    return Vue.axios
      .get(`/cases`)
      .then((response) => {
        commit("setcases", response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(function () {
        commit("requested");
      });
  },
  postCase(
    { dispatch, commit },
    {
      idOrganizationalUnit,
      caseName,
      duration,
      idCaseTemplate,
      idCondition,
      caseFields,
    }
  ) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post("/cases", {
          idOrganizationalUnit,
          caseName,
          duration,
          idCaseTemplate,
          idCondition,
          caseFields,
        })
        .then((response) => response.data)
        .then(async (data) => {
          await dispatch("fetchCases");
          commit("setCaseSelectd", data);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  postSubcase({ commit }, { idCase, name, idMasterForm, session, api }) {
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.axios
        .post("/subcases", {
          name,
          idCase,
          idMasterForm,
        })
        .then((response) => response.data)
        .then((data) => {
          commit("setCaseSelectd", defaultCases);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async addCases({ commit }, user) {
    const response = await axios.post("​/api​/cases", user);
    commit("addNewUser", response.data);
  },
  async deleteCases(
    { commit },
    { api, session, idOrganizationalUnit, idCase }
  ) {
    api.setHeaderToken(session.get("healex-token"));
    await api.axios.delete(`/cases/${idCase}`);
    commit("removeCase", { idOrganizationalUnit, idCase });
    commit("setCaseSelectd", defaultCases);
  },
  async fetchOUs({ commit }, payload) {
    let { session, api } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .getAllEnablesOU()
      .then((response) => {
        // handle success
        commit("setOUs", response.data);

        if (
          response.data.organizationalUnits.length > 0 &&
          response.data.organizationalUnits[0].idRole <= 2
        ) {
          commit("admin");
        } else {
          commit("notAdmin");
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async fetchUserList({ commit }, payload) {
    let { session, api, idOU } = payload;
    api.setHeaderToken(session.get("healex-token"));
    await api
      .getUsersOU(idOU)
      .then((response) => {
        // handle success
        commit("setuserlist", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async changeUser({ commit }, payload) {
    let { session, api, caseInfo, newOwner, nameOwner } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .ChangeCaseOwner(caseInfo, newOwner)
      .then(() => {
        // handle success
        caseInfo.owner = nameOwner;
        commit("updateOwnerCase", { caseInfo, nameOwner });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  getCaseSelectd({ commit }, { api, idCase }) {
    return new Promise((resolve, reject) => {
      api.getCase({ idCase }).then(
        (response) => {
          commit("setCaseSelectd", response.data);
          commit("setCaseHeaderId", response.data.idCase);
          resolve(response);
        },
        (error) => {
          console.log("Case error: ", error);
          commit("setCaseSelectd", defaultCases);
          reject(error);
        }
      );
    });
  },
  getCaseHeader({ commit }, { session, api, idCase }) {
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.axios.get(`/cases/${idCase}/file`).then(
        (response) => {
          commit("setCaseHeader", response.data);
          resolve(response);
        },
        (error) => {
          console.log("get Case Selectd: ", error);
          commit("setCaseHeader", {});
          reject(error);
        }
      );
    });
  },
  putCaseHeader(
    { commit },
    { session, api, idCase, file, duration, idStudyType, resources, caseFields }
  ) {
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.axios
        .put(`/cases/${idCase}`, {
          file,
          duration,
          idStudyType,
          resources,
          caseFields,
        })
        .then(
          (response) => {
            commit("setCaseHeader", response.data);
            resolve(response);
          },
          (error) => {
            commit("setCaseHeaderFile", undefined);
            reject(error);
          }
        );
    });
  },
  renameCaseHeader({ commit }, { session, api, idCase, newName }) {
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.axios.put(`/cases/rename/${idCase}`, { newName }).then(
        (response) => {
          commit("renameCaseHeader", newName);
          resolve(response);
        },
        (error) => {
          console.log("rename case header: ", error);
          commit("renameCaseHeader", undefined);
          reject(error);
        }
      );
    });
  },
  getCaseHeaderStatus({ commit }, { session, api, idCase }) {
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.axios.get(`/cases/status/${idCase}`).then(
        (response) => {
          commit("setCaseHeaderStatus", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Get Case Header Status: ", error);
          commit("setCaseHeaderStatus", { status: undefined, name: undefined });
          reject(error);
        }
      );
    });
  },
  getMasterFormsInOU({ commit }, { session, api, idOrganizationalUnit }) {
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.axios
        .get(`/master-forms/organizational-unit/${idOrganizationalUnit}`)
        .then(
          (response) => {
            commit("setMasterFormList", response.data);
            resolve(response);
          },
          (error) => {
            console.log("Get Case Header Status: ", error);
            commit("setMasterFormList", []);
            reject(error);
          }
        );
    });
  },
  getUserCollaboration({ commit }, payload) {
    const { api, idCase } = payload;
    return new Promise((resolve, reject) => {
      api.getUserCollaboration(idCase).then(
        (response) => {
          commit("setUserCollaboration", response.data);
          resolve(response.data);
        },
        (error) => {
          commit("setUserCollaboration", null);
          reject(error.response.data.message);
        }
      );
    });
  },
  postUserCollaboration({ dispatch }, payload) {
    const { api, idCase, userName } = payload;
    return new Promise((resolve, reject) => {
      api.PostUserCollaboration(idCase, userName).then(
        (response) => {
          dispatch("getUserCollaboration", { api, idCase }).then(() => {
            resolve(response.data);
          });
        },
        (error) => {
          reject(error.response.data.message);
        }
      );
    });
  },
  deleteUserCollaboration({ dispatch }, payload) {
    const { api, idCase, userName } = payload;
    return new Promise((resolve, reject) => {
      api.DeleteUserCollaboration(idCase, userName).then(
        (response) => {
          dispatch("getUserCollaboration", { api, idCase }).then(() => {
            resolve(response.data);
          });
        },
        (error) => {
          reject(error.response.data.message);
        }
      );
    });
  },
  getCaseTemplates({ commit }, payload) {
    let { api, idOrganizationalUnit } = payload;

    return new Promise((resolve, reject) => {
      api.getCaseTemplatesOU(idOrganizationalUnit).then(
        (response) => {
          commit("setTemplates", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Error on case template get: ", error);
          commit("setTemplates", []);
          reject(error);
        }
      );
    });
  },
  getCaseTemplatesbyOU({ commit }, payload) {
    let { api, idOrganizationalUnit, enabled } = payload;
    return new Promise((resolve, reject) => {
      api.getCaseTemplatesbyOU(enabled, idOrganizationalUnit).then(
        (response) => {
          commit("setTemplatesbyOU", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Error on case template get: ", error);
          commit("setTemplatesbyOU", []);
          reject(error);
        }
      );
    });
  },
  postNewCaseTemplates({ dispatch }, payload) {
    const { api, idOrganizationalUnit, caseTemplateName } = payload;
    return new Promise((resolve, reject) => {
      api.postNewCaseTemplates(caseTemplateName, idOrganizationalUnit).then(
        (response) => {
          dispatch("getCaseTemplatesbyOU", { api }).then(() => {
            resolve(response.data);
          });
        },
        (error) => {
          reject(error.response.data.message);
        }
      );
    });
  },
  deleteCaseTemplates({ dispatch }, payload) {
    const { api, idCaseTemplate } = payload;
    return new Promise((resolve, reject) => {
      api.deleteCaseTemplates(idCaseTemplate).then(
        (response) => {
          dispatch("getCaseTemplatesbyOU", { api }).then(() => {
            resolve(response.data);
          });
        },
        (error) => {
          reject(error.response.data.message);
        }
      );
    });
  },
  putCaseTemplatesEnabled({ dispatch }, payload) {
    const { api, idCaseTemplate, idOrganizationalUnit, enabled } = payload;
    return new Promise((resolve, reject) => {
      api.putCaseTemplatesEnabled(idCaseTemplate).then(
        (response) => {
          dispatch("getCaseTemplatesbyOU", {
            api,
            idOrganizationalUnit,
            enabled,
          }).then(() => {
            resolve(response.data);
          });
        },
        (error) => {
          reject(error.response.data.message);
        }
      );
    });
  },
  putParameters({ commit }, { api, idCase, parameters }) {
    commit("request");
    return new Promise((resolve, reject) => {
      api.axios
        .put(
          `/parameters/case`,
          parameters.map(function (x) {
            x.idCase = idCase;
            return x;
          })
        )
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            console.log("parameter update error: ", error);
            reject(error);
          }
        )
        .finally(() => {
          commit("requested");
        });
    });
  },
  putOpenStatus({ commit }, payload) {
    let { idCostCalculation } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios.put(`/cases/${idCostCalculation}/open`).then(
        (response) => {
          commit("changeCaseStatus", response.data);
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  putEditStatus({ commit }, payload) {
    let { idCostCalculation } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios.put(`/cases/${idCostCalculation}/edit`).then(
        (response) => {
          commit("changeCaseStatus", response.data);
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  putAcceptedStatus({ commit }, payload) {
    let { idCases } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios.put(`/cases/${idCases}/accept`).then(
        (response) => {
          commit("changeCaseStatus", response.data);
          commit("changeCaseDetailStatus", response.data);
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  putSubmittedStatus({ commit }, payload) {
    let { idCases } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios.put(`/cases/${idCases}/submit`).then(
        (response) => {
          commit("changeCaseStatus", response.data);
          commit("changeCaseDetailStatus", response.data);
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  putClosedStatus({ commit }, payload) {
    let { idCases } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios.put(`/cases/${idCases}/close`).then(
        (response) => {
          commit("changeCaseStatus", response.data);
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  putReturnStatus({ commit }, payload) {
    let { idCases } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios.put(`/cases/${idCases}/return`).then(
        (response) => {
          commit("changeCaseStatus", response.data);
          commit("changeCaseDetailStatus", response.data);
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.text);
        }
      );
    });
  },
  duplicateCase({ dispatch, commit }, { session, api, idCase, name }) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(`/cases/${idCase}/duplicate`, { name })
        .then(async ({ data }) => {
          await dispatch("fetchCases", {
            session: session,
            api: api,
          });
          commit("setCaseSelectd", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReviewersDistributionList({ commit }, payload) {
    let { idCostCalculation } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(
          `/cost-calculation/${idCostCalculation}/reviewers-distribution-list`
        )
        .then(
          (response) => {
            commit("setReviewers", response.data);
            resolve(response.data);
          },
          (error) => {
            commit("setReviewers", []);
            reject(error.response.data.text);
          }
        );
    });
  },
  postReviewerDistributionList({ commit }, payload) {
    let { idCostCalculation, name } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(
          `/cost-calculation/${idCostCalculation}/reviewers-distribution-list`,
          { name }
        )
        .then(
          (response) => {
            commit("addReviewer", response.data);
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data.text);
          }
        );
    });
  },
  deleteReviewerDistributionList({ commit }, payload) {
    let { idCostCalculation, reviewer } = payload;
    return new Promise((resolve, reject) => {
      Vue.axios
        .delete(
          `/cost-calculation/${idCostCalculation}/reviewers-distribution-list/${reviewer}`
        )
        .then(
          (response) => {
            commit("deleteReviewer", reviewer);
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data.text);
          }
        );
    });
  },
};

const mutations = {
  changeCaseStatus(state, response) {
    state.caseHeader.idStatus = response.idStatus;
    state.caseHeader.status = response.status;
  },
  changeCaseDetailStatus(state, response) {
    state.caseSelectd.idStatus = response.idStatus;
    state.caseSelectd.status = response.status;
  },
  setcases: (state, cases) => (state.cases = cases),
  updateOwnerCase(state, { caseInfo, nameOwner }) {
    const index = state.cases.findIndex((x) => x.idCase === caseInfo.idCase);
    state.cases[index].owner = nameOwner;
  },
  setOUs: (state, ous) => (state.organizations = ous),
  addCase: (state, user) => state.cases.unshift(user),
  removeCase: (state, CaseId) => {
    state.cases.splice(
      state.cases.findIndex((caseInfo) => caseInfo.idCase === CaseId.idCase),
      1
    );
  },
  request: (state) => {
    state.loading = true;
  },
  requested: (state) => {
    state.loading = false;
  },
  admin: (state) => {
    state.isAdmin = true;
  },
  notAdmin: (state) => {
    state.isAdmin = false;
  },
  setuserlist: (state, list) => (state.UserList = list),
  setCaseSelectd(state, caseSelectd) {
    state.caseSelectd = caseSelectd;
  },
  setCaseHeader: (state, caseHeader) => {
    const resourcesJSON = JSON.parse(caseHeader.resources);
    state.caseHeader = caseHeader;
    state.caseHeader.resources = resourcesJSON;
  },
  setCaseHeaderFile: (state, file) => {
    state.caseHeader.file = file;
  },
  renameCaseHeader: (state, name) => {
    state.caseHeader.name = name;
  },
  setCaseHeaderStatus(state, status) {
    state.caseHeader.status = status.name;
    state.caseHeader.idStatus = status.status;
  },
  setCaseHeaderId(state, id) {
    state.caseHeader.idCase = id;
  },
  DeleteSubcase(state, idSubcase) {
    state.caseSelectd.subcases = state.caseSelectd.subcases.filter(
      (sc) => sc.idSubcase !== idSubcase
    );
  },
  setMasterFormList(state, masterFormList) {
    state.masterFormList = masterFormList;
  },
  setTemplates(state, templateList) {
    state.caseTemplates = templateList;
  },
  setStudyType(state, condition) {
    state.caseHeader.studyType = condition.length
      ? condition[0].conditionName
      : "None";
  },
  setUserCollaboration(state, users) {
    state.userCollaboration = users;
  },
  setTemplatesbyOU(state, templateList) {
    state.caseTemplatesbyOU = templateList;
  },
  addSubcase(state, subcase) {
    state.caseSelectd.subcases.push(subcase);
  },
  setReviewers(state, reviewersList) {
    state.reviewersList = reviewersList;
  },
  addReviewer(state, reviewersUsername) {
    state.caseHeader.reviewerDistributionList.push(reviewersUsername);
  },
  deleteReviewer(state, reviewersUsername) {
    const index = state.caseHeader.reviewerDistributionList.findIndex(
      (x) => x.username == reviewersUsername
    );
    console.log(index, reviewersUsername);
    state.caseHeader.reviewerDistributionList.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
