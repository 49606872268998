<template>
  <div class="float-right d-inline-block">
    <!-- <b-form-select v-model="$i18n.locale" :options="langs" variant="primary"></b-form-select> -->
    <b-nav-item-dropdown
      v-if="isNav"
      :text="$i18n.locale"
      :variant="variant"
      id="LocaleChanger"
    >
      <b-dropdown-item
        v-for="(lang, i) in langs"
        :key="`Lang-${i}`"
        :value="lang"
        @click="onClick(lang)"
        :id="'Language' + lang.text.toUpperCase()"
        >{{ lang.text }}</b-dropdown-item
      >
    </b-nav-item-dropdown>
    <b-dropdown v-else :text="$i18n.locale" :variant="variant">
      <b-dropdown-item
        v-for="(lang, i) in langs"
        :key="`Lang-${i}`"
        :value="lang"
        @click="onClick(lang)"
        >{{ lang.text }}</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "locale-changer",
  props: {
    variant: {
      type: String,
      required: false,
      default: "outline-primary",
    },
    isNav: {
      type: String,
      required: false,
      default: "outline-primary",
    },
  },
  data() {
    return {
      langs: [
        { text: "en", value: "en-IE" },
        { text: "de", value: "de-DE" },
      ],
    };
  },
  created() {
    this.$nextTick(function () {
      const currentLocale = this.$i18n.locale;
      let lang = this.langs.find((x) => x.text === currentLocale);
      if (!lang) {
        lang = this.langs[1];
      }
      this.hideMessage();
      this.$cookies.set("locale", lang.text);
      this.setLanguage({ api: this.$api, culture: lang.value });
    });
  },
  methods: {
    ...mapActions({
      setLanguage: "SET_LANGUAGE",
      hideMessage: "DELETE_MESSAGE",
    }),
    onClick(language) {
      this.$i18n.locale = language.text;
      this.$cookies.set("locale", language.text);
      this.hideMessage();
      this.setLanguage({ api: this.$api, culture: language.value });
    },
  },
};
</script>

<style lang="scss" scoped></style>
