//import axios from 'axios';
const state = {
  caseInfo: {},
  template: {},
  salaryList: [],
  caseId: -1,
  loading: false,
  caseState: 0,
  currentEditor: null,
  studyTypes: [],
  surcharges: [],
  surchargeTypes: [],
  summaryFile: [],
  patients: 0,
  perPatient: false,
  duration: 0,
  subcaseName: "",
  caseName: "",
  allowPerPatients: null,
};

const getters = {
  subcaseFile: (state) => state.caseInfo,
  subcaseName: (state) => state.subcaseName,
  SummaryFile: (state) => state.summaryFile,
  templateFile: (state) => state.template,
  SalaryList: (state) => state.salaryList,
  IDCase: (state) => state.caseId,
  loading: (state) => state.loading,
  State: (state) => state.caseState,
  Editor: (state) => state.currentEditor,
  StudyTypes: (state) => state.studyTypes,
  Surcharges: (state) => state.surcharges,
  SurchargeTypes: (state) => state.surchargeTypes,
  Patients: (state) => state.patients,
  PerPatient: (state) => state.perPatient,
  Duration: (state) => state.duration,
  caseName: (state) => state.caseName,
  parameters: (state) => state.caseInfo.Parameters,
  allowPerPatients: (state) => state.allowPerPatients,
};

const actions = {
  GetSubcase({ commit }, payload) {
    let { session, api, idSubcase } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    return api
      .GetSubcase(idSubcase)
      .then((response) => {
        // handle success
        let file = JSON.parse(response.data.file);
        let summaryFile = JSON.parse(response.data.summaryFile);
        commit("setcase", file);
        commit("setState", response.data.idStatus);
        commit("setEditor", response.data.currentEditor);
        commit("setSummaryFile", summaryFile);
        commit("setPatients", response.data.patients);
        commit("setPerPatient", response.data.allowPerPatients);
        commit("setDuration", response.data.duration);
        commit("setName", response.data.name);
        commit("setCaseName", response.data.caseName);
        commit("setAllowPerPatients", response.data.allowPerPatients);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  duplicate({ commit }, { session, api, idSubcase, name }) {
    api.setHeaderToken(session.get("healex-token"));
    return new Promise((resolve, reject) => {
      api.axios
        .post(`/subcases/${idSubcase}/duplicate`, { name })
        .then((response) => {
          commit("Cases/addSubcase", response.data, { root: true });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async GetTemplate({ commit }, payload) {
    let { session, api, idMasterForm } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .getMasterForm(idMasterForm)
      .then((response) => {
        // handle success
        let file = {
          general: JSON.parse(response.data.general),
          specific: JSON.parse(response.data.specific),
        };
        commit("settemplate", file);
        commit("setSummaryFile", JSON.parse(response.data.summaryFile));
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  PutSubcase({ commit }, payload) {
    let { session, api, idSubcase, file, name, summaryFile, patients } =
      payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");

    return new Promise((resolve, reject) => {
      api.PutSubcase(idSubcase, file, name, summaryFile, patients).then(
        (response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            reject(response.data.message);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async PostSubcase({ commit }, payload) {
    let {
      session,
      api,
      idOrganizationalUnit,
      file,
      caseName,
      idCase,
      summary,
      patients,
      perPatient,
    } = payload;
    api.setHeaderToken(session.get("healex-token"));
    let userName = session.get("healex-userName");
    file.UserName = userName;

    commit("request");
    await api
      .PostSubcase(
        caseName,
        JSON.stringify(file),
        idOrganizationalUnit,
        idCase,
        JSON.stringify(summary),
        patients,
        perPatient
      )
      .then((response) => {
        commit("setIdCase", response.data.idSubcase);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async GetSalaryTypes({ commit }, payload) {
    let { session, api } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .getSalaryTypes()
      .then((response) => {
        // handle success
        commit("setSalaryList", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async GetSalaryInfo({ commit }, payload) {
    let { session, api, idSalary, index } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .getSalaries(idSalary)
      .then((response) => {
        // handle success
        commit("setSalaryInfo", { data: response.data, index: index });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async GetSubcaseState({ commit }, payload) {
    let { session, api, idSubcase } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api
      .GetSubcaseState(idSubcase)
      .then((response) => {
        // handle success
        commit("setState", response.data.status);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  GetStudyTypes({ commit }, payload) {
    let { session, api, enabled } = payload;
    api.setHeaderToken(session.get("healex-token"));

    return api
      .GetStudyTypes(enabled)
      .then((response) => {
        // handle success
        commit("setStudyTypes", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async GetSurcharges({ commit }, payload) {
    let { session, api, idCondition } = payload;
    api.setHeaderToken(session.get("healex-token"));

    await api
      .GetSurcharges(idCondition)
      .then((response) => {
        // handle success
        commit("setSurcharges", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async GetSurchargeTypes({ commit }, payload) {
    let { session, api } = payload;
    api.setHeaderToken(session.get("healex-token"));

    await api
      .GetSurchargesTypes(true)
      .then((response) => {
        // handle success
        commit("setSurchargeTypes", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async DeleteSubcase({ commit }, payload) {
    let { session, api, idSubcase } = payload;
    api.setHeaderToken(session.get("healex-token"));
    commit("request");
    await api.axios
      .delete(`/subcases/${idSubcase}`)
      .then(() => {
        commit("Cases/DeleteSubcase", idSubcase, { root: true });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("requested");
      });
  },
  putParameters({ commit }, { api, idSubcase, parameters }) {
    commit("request");
    return new Promise((resolve, reject) => {
      api.axios
        .put(`/parameters/subcases?idSubcase=${idSubcase}`, parameters)
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            console.log("parameter update error: ", error);
            reject(error);
          }
        )
        .finally(() => {
          commit("requested");
        });
    });
  },
};

const mutations = {
  setAllowPerPatients(state, allowPerPatients) {
    state.allowPerPatients = allowPerPatients;
  },
  setcase: (state, caseInfo) => (state.caseInfo = caseInfo),
  settemplate: (state, caseInfo) => (state.template = caseInfo),
  setState: (state, caseState) => (state.caseState = caseState),
  setEditor: (state, editor) => (state.currentEditor = editor),
  setIdCase: (state, caseId) => (state.caseId = caseId),
  setSalaryList: (state, list) => (state.salaryList = list),
  setSalaryInfo: (state, info) =>
    (state.salaryList[info.index].Salaries = info.data),
  updatecase: (state, caseFile) => (state.caseInfo = caseFile),
  request: (state) => {
    state.loading = true;
  },
  requested: (state) => {
    state.loading = false;
  },
  setStudyTypes: (state, data) => {
    state.studyTypes = data;
  },
  setSurcharges: (state, data) => {
    state.surcharges = data;
  },
  setSurchargeTypes: (state, data) => {
    state.surchargeTypes = data;
  },
  setSummaryFile: (state, data) => {
    state.summaryFile = data;
  },
  setPatients: (state, patients) => (state.patients = patients),
  setPerPatient: (state, perPatient) => (state.perPatient = perPatient),
  setDuration: (state, duration) => (state.duration = duration),
  setName: (state, subcaseName) => (state.subcaseName = subcaseName),
  setCaseName: (state, caseName) => (state.caseName = caseName),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
